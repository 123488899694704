import React from 'react'
import Hero from '../components/Hero'

export default function HomePage() {
  return (
    <>
      <Hero/>
    </>
  )
}
